.ai-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(15px, 3vw, 25px);
    /* Responsive gap */
    padding: clamp(10px, 2vw, 20px);
    /* Responsive padding */
}

.ai-orbit-container {
    position: relative;
    width: clamp(100px, 15vw, 150px);
    /* Scales between 100px and 150px */
    height: clamp(100px, 15vw, 150px);
}

.ai-orbit-ring {
    position: absolute;
    border: clamp(2px, 0.3vw, 3px) solid transparent;
    /* Responsive border */
    border-radius: 50%;
    animation: orbit 1.8s infinite ease-in-out;
    box-shadow: 0 0 clamp(10px, 1.5vw, 15px) rgba(147, 51, 234, 0.7);
}

.ring-1 {
    width: 100%;
    height: 100%;
    border-top-color: #9333ea;
    animation-delay: 0s;
}

.ring-2 {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-right-color: #c084fc;
    animation-direction: reverse;
    animation-delay: 0.4s;
}

.ring-3 {
    width: 60%;
    height: 60%;
    top: 20%;
    left: 20%;
    border-bottom-color: #d8b4fe;
    animation-delay: 0.8s;
}

.ai-core {
    position: absolute;
    width: clamp(30px, 4vw, 40px);
    /* Responsive core size */
    height: clamp(30px, 4vw, 40px);
    top: 33%;
    left: 33%;
    background: radial-gradient(circle, #9333ea 0%, transparent 70%);
    border-radius: 50%;
    box-shadow: 0 0 clamp(15px, 2vw, 20px) #9333ea, 0 0 clamp(30px, 4vw, 40px) rgba(147, 51, 234, 0.5);
}

.ai-core-pulse {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #9333ea;
    border-radius: 50%;
    opacity: 0.4;
    animation: corePulse 1.2s infinite ease-in-out;
}

.ai-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(8px, 1.5vw, 12px);
}

.loading-text {
    color: #9333ea;
    font-family: 'Questrial', sans-serif;
    font-size: clamp(16px, 2.5vw, 20px);
    /* Responsive font size */
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: clamp(2px, 0.3vw, 3px);
    text-shadow: 0 0 clamp(8px, 1vw, 10px) rgba(147, 51, 234, 0.7);
    text-align: center;
}

.changing-text {
    color: #c084fc;
    font-family: 'Questrial', sans-serif;
    font-size: clamp(12px, 2vw, 16px);
    font-weight: 500;
    margin: 0;
    text-align: center;
    animation: fadeInOut 1.5s infinite;
    min-height: clamp(20px, 3vw, 24px);
    text-shadow: 0 0 clamp(6px, 1vw, 8px) rgba(192, 132, 252, 0.5);
}

.success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(15px, 2vw, 20px);
}

.success-title {
    color: #9333ea;
    font-family: 'Questrial', sans-serif;
    margin: 0;
    font-size: clamp(24px, 4vw, 28px);
    text-shadow: 0 0 clamp(8px, 1vw, 10px) rgba(147, 51, 234, 0.5);
}

.success-tick {
    width: clamp(50px, 8vw, 60px);
    /* Responsive tick size */
    height: clamp(50px, 8vw, 60px);
    animation: tickAppear 0.5s ease-in-out;
}

.success-tick svg {
    filter: drop-shadow(0 0 clamp(8px, 1vw, 10px) rgba(147, 51, 234, 0.5));
    width: 100%;
    height: 100%;
}

.success-message {
    text-align: center;
    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.5;
    color: #fff;
    margin: 0;
}

.countdown-text {
    text-align: center;
    font-size: clamp(12px, 1.8vw, 14px);
    color: #c084fc;
    /* Lighter purple for contrast */
    margin: 0;
    font-family: 'Questrial', sans-serif;
    text-shadow: 0 0 clamp(4px, 0.5vw, 6px) rgba(192, 132, 252, 0.5);
}

@keyframes orbit {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(1.1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes corePulse {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }

    50% {
        transform: scale(1.6);
        opacity: 0.1;
    }

    100% {
        transform: scale(1);
        opacity: 0.4;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(clamp(6px, 1vw, 8px));
    }

    20% {
        opacity: 1;
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(clamp(-6px, -1vw, -8px));
    }
}

@keyframes tickAppear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Media query for desktop-specific adjustments */
@media (min-width: 768px) {
    .ai-orbit-container {
        width: 150px;
        /* Larger on desktop */
        height: 150px;
    }

    .ai-core {
        width: 40px;
        height: 40px;
        top: 38%;
        /* Adjusted for larger container */
        left: 38%;
    }

    .loading-text {
        font-size: 24px;
        /* Slightly larger on desktop */
        letter-spacing: 4px;
    }

    .changing-text {
        font-size: 18px;
    }

    .success-title {
        font-size: 32px;
        /* Larger on desktop */
    }

    .success-tick {
        width: 70px;
        /* Slightly larger on desktop */
        height: 70px;
    }

    .success-message {
        font-size: 18px;
    }
}
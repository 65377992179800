.footer-container {
    background-color: black;
    padding: 40px 80px;
    color: #fff;
    font-family: "Questrial", sans-serif;
}

.header-section {
    text-align: left;
}

.paragraph {
    margin: 0 0 20px 0;
    font-size: 16px;
}

.button-container {
    margin: 60px 0 30px 0;
}

.send-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.divider {
    border: none;
    height: 1px !important;
    background-color: #222;
    margin: 40px 0;
}

.footer-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.links-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.link-column,
.contact-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.link {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    margin: 0;
}

.social-copyright-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.social-icons {
    display: flex;
    gap: 20px;
}

.icon {
    color: #fff;
    cursor: pointer;
    font-size: 30px;
}

.copyright-container {
    /* No specific styles needed here for desktop */
}

.copyright {
    color: #fff;
    font-size: 14px;
    margin: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .footer-container {
        padding: 20px;
    }

    .button-container {
        margin: 30px 0 20px 0;
    }

    .send-button {
        padding: 8px 16px;
    }

    .links-section {
        flex-direction: column;
        gap: 20px;
    }

    .social-copyright-section {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .social-icons {
        gap: 15px;
        justify-content: center;
        width: 100%;
    }

    .icon {
        font-size: 24px;
    }

    .copyright-container {
        width: 100%;
        text-align: center;
    }
}
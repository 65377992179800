.registration-container {
    min-height: 100vh;
    display: flex;
    background-color: #000414;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    background-size: 20px 20px;
    color: #fff;
    font-family: "Questrial", sans-serif;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
}

.left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    border-right: 2px solid rgba(102, 153, 255, 0.5);
    position: relative;
    z-index: 1;
    height: 100vh;
}

.bootcamp-header {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.bootcamp-title {
    font-size: 3.5rem;
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    white-space: nowrap;
}

.cursor {
    font-size: 3.5rem;
    color: #3d33cc;
    animation: blink 0.8s infinite;
}

.bootcamp-subtitle {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 10px rgba(168, 102, 255, 0.6);
    letter-spacing: 1px;
}

.bootcamp-tagline {
    font-size: 1.2rem;
    color: #66cccc;
    text-shadow: 0 0 8px rgba(102, 204, 204, 0.5);
    margin-top: 10px;
    font-style: italic;
}

.bootcamp-details {
    width: 100%;
}

.cards-marquee-wrapper {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 400px;
    overflow: hidden;
}

.cards-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.cards-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 200%;
    /* Double height to accommodate duplicated content */
}

.cards-column-left {
    animation: scroll-down 20s linear infinite;
}

.cards-column-right {
    animation: scroll-up 20s linear infinite;
}

.cards-group {
    display: flex;
    flex-direction: column;
    /* gap: 40px; */
}

.bootcamp-card {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(102, 153, 255, 0.3);
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    transition: all 0.3s ease;
    margin: 20px 0px;
}

.bootcamp-card h3 {
    font-size: 1.1rem;
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
    text-shadow: 0 0 5px rgba(102, 153, 255, 0.5);
}

.bootcamp-card p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.4;
}

.blur-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(to bottom, rgba(0, 4, 20, 0.9), transparent);
    z-index: 2;
}

.blur-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(to top, rgba(0, 4, 20, 0.9), transparent);
    z-index: 2;
}

.right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.form-container {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    background: rgba(0, 4, 20, 0.9);
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(186, 102, 255, 0.425);
    border: 2px solid rgba(173, 102, 255, 0.2);
    position: relative;
    z-index: 2;
}

.form-header {
    text-align: center;
    margin-bottom: 25px;
}

.form-header .logo {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    object-fit: contain;
}

.form-header h2 {
    font-size: 1.6rem;
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
}

.form-header p {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 5px;
}

.access-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-step {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.data-port {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.data-port label {
    font-size: 0.85rem;
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: inherit;
    letter-spacing: 1px;
}

.access-form input {
    padding: 10px 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(161, 102, 255, 0.4);
    border-radius: 5px;
    color: #fff;
    font-size: 0.9rem;
    font-family: "Orbitron", sans-serif;
    outline: none;
    transition: all 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.access-form input:focus {
    border-color: #8233cc;
    box-shadow: 0 0 12px rgba(194, 102, 255, 0.5);
    background: rgba(255, 255, 255, 0.1);
}

.access-form input:invalid:focus {
    border-color: #ff3366;
    box-shadow: 0 0 12px rgba(255, 51, 102, 0.5);
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.next-button,
.launch-button {
    background: #8f33cc !important;
    color: #fff !important;
    padding: 8px !important;
    border-radius: 5px !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    letter-spacing: 1px !important;
    transition: all 0.3s ease !important;
    flex: 1;
    min-width: 100px;
    font-family: "Questrial", sans-serif !important;
}

.prev-button {
    border: 1px solid rgba(219, 102, 255, 0.3) !important;
    color: #fff !important;
    padding: 8px !important;
    border-radius: 5px !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    letter-spacing: 1px !important;
    transition: all 0.3s ease !important;
    flex: 1;
    min-width: 100px;
    font-family: "Questrial", sans-serif !important;
}

.next-button:hover,
.launch-button:hover {
    border: 1px solid #b64de6 !important;
    box-shadow: 0 0 15px rgba(168, 102, 255, 0.7);
}

.prev-button:hover {
    background: rgba(212, 102, 255, 0.5) !important;
}

.next-button:disabled,
.launch-button:disabled {
    background: rgba(199, 102, 255, 0.3) !important;
    opacity: 0.6 !important;
    cursor: not-allowed !important;
}

.alternate-path {
    margin: 20px 0;
    text-align: center;
    position: relative;
}

.alternate-path span {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.4);
    background: rgba(0, 4, 20, 0.9);
    padding: 0 10px;
    position: relative;
    z-index: 1;
}

.alternate-path::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg,
            transparent,
            rgba(181, 102, 255, 0.5),
            transparent);
}

.google-portal {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.portal-spinner {
    color: #ad33cc;
}

.return-gate {
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.gate-button {
    color: #c599ff !important;
    font-size: 0.85rem !important;
    text-transform: inherit !important;
    padding: 0 !important;
    font-weight: 600 !important;
    transition: all 0.3s ease !important;
}

.gate-button:hover {
    color: #fff !important;
    text-shadow: 0 0 10px rgba(194, 102, 255, 0.7);
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes scroll-up {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes scroll-down {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0);
    }
}

.cards-marquee-wrapper:hover .cards-column-left,
.cards-marquee-wrapper:hover .cards-column-right {
    animation-play-state: paused;
}

.mobile-title-container {
    display: none;
}

@media (max-width: 1024px) {
    .cards-marquee-wrapper {
        height: 300px;
    }

    .cards-container {
        gap: 15px;
    }

    .cards-group {
        gap: 15px;
    }

    .left-panel {
        padding: 30px;
    }

    .right-panel {
        padding: 30px;
    }
}

@media (max-width: 768px) {
    .registration-container {
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .left-panel {
        display: none;
    }

    .right-panel {
        flex: none;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
    }

    .mobile-title-container {
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .mobile-title {
        font-size: 1.8rem;
        color: #fff;
        margin-bottom: 10px;
    }

    .mobile-subtitle {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.8);
    }

    .hashtag {
        color: #fff;
    }

    .gradient-text {
        background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .form-container {
        max-width: 87%;
        padding: 20px;
        margin: 0 auto;
        border-radius: 15px;
        box-shadow: 0 0 15px rgba(186, 102, 255, 0.425);
    }

    .form-header {
        margin-bottom: 20px;
    }

    .logo {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .form-header h2 {
        font-size: 1.4rem;
    }

    .form-header p {
        font-size: 0.75rem;
    }

    .access-form {
        gap: 15px;
    }

    .form-step {
        gap: 12px;
    }

    .data-port {
        gap: 5px;
    }

    .data-port label {
        font-size: 0.8rem;
    }

    .access-form input {
        padding: 8px 10px;
        font-size: 0.85rem;
    }

    .button-group {
        gap: 8px;
    }

    .next-button,
    .prev-button,
    .launch-button {
        padding: 6px !important;
        font-size: 0.8rem !important;
        min-width: 90px;
    }

    .alternate-path {
        margin: 15px 0;
    }

    .alternate-path span {
        font-size: 0.75rem;
        padding: 0 8px;
    }

    .google-portal {
        margin-bottom: 10px;
    }

    .return-gate {
        font-size: 0.8rem;
    }

    .gate-button {
        font-size: 0.8rem !important;
    }

    .cards-marquee-wrapper {
        height: 250px;
    }

    .cards-column-left {
        animation: scroll-down 15s linear infinite;
    }

    .cards-column-right {
        animation: scroll-up 15s linear infinite;
    }
}
.marquee-container {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    display: flex;
    align-items: center;
}

.marquee-content {
    display: flex;
    white-space: nowrap;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    cursor: pointer;
    font-family: "Questrial";
}

.marquee-content.left {
    animation-name: scroll-left;
}

.marquee-content.right {
    animation-name: scroll-right;
}

@keyframes scroll-left {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

@keyframes scroll-right {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0%);
    }
}

.marquee-content:hover {
    animation-play-state: paused;
}

.marquee-item {
    margin: 0 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marquee-item>h1 {
    text-align: center;
    text-wrap: wrap;
    font-size: 20px;
    font-family: "Questrial";
}

.marquee-item>img {
    width: 200px;
    height: 100px;
    object-fit: contain;
}

.review_card_container {
    width: 300px;
    /* Adjust width as needed */
    padding: 10px;
    border: 1px solid #ccc;
    /* Optional border for styling */
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    /* Adds spacing between items */
}

.reviews_container {
    word-wrap: break-word;
    /* Ensures long words wrap correctly */
    white-space: normal;
    /* Allows text to wrap to the next line */
    overflow-wrap: break-word;
    /* Additional word-breaking support for all browsers */
    line-height: 1.5;
    /* Improves readability with proper line height */
    font-size: 16px;
    color: #333;
}

.review_card_container div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
}

.review_card_container h4 {
    margin: 0;
    font-size: 18px;
    color: #555;
    font-weight: 600;
}
.educationTopbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(18px) saturate(116%);
    background-color: rgba(246, 253, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 100;
    box-sizing: border-box;
}

.logo>img {
    width: 180px;
    cursor: pointer;
}

.right-section {
    display: flex;
    align-items: center;
}

.auth-buttons {
    display: flex;
    gap: 15px;
}

.sign-in-btn {
    color: #fff !important;
    font-size: 14px !important;
    padding: 6px 20px !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    border: 1px solid rgba(179, 49, 255, 0.7) !important;
    background: rgba(255, 255, 255, 0.05) !important;
    transition: all 0.3s ease !important;
}

.sign-in-btn:hover {
    background: rgba(255, 255, 255, 0.15) !important;
}

.sign-up-btn {
    background: linear-gradient(45deg, #8031ff, #b331ff) !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 6px 20px !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    transition: all 0.3s ease !important;
}

.sign-up-btn:hover {
    background: linear-gradient(45deg, #6b27d6, #9a27d6) !important;
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 20px;
    transition: background 0.3s ease;
    margin: 0px 20px;
}

.user-profile:hover {
    background: rgba(255, 255, 255, 0.1);
}

.user-name {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.popover-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-width: 150px;
}

.popover-item {
    background: none;
    border: none;
    color: #fff;
    padding: 8px 15px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.popover-item:hover {
    background: rgba(255, 255, 255, 0.1);
}

.logout-btn {
    color: #ff6b6b;
}

@media only screen and (max-width: 768px) {
    .logo>img {
        width: 150px;
    }

    .educationTopbarContainer {
        padding: 10px 20px;
    }

    .sign-in-btn, .sign-up-btn {
        padding: 5px 15px !important;
        font-size: 13px !important;
    }
}
.workshopdetails_main {
    height: 100vh;
    background-color: #000414;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    background-size: 20px 20px;
    overflow-y: scroll;
    color: #fff;
    font-family: "Questrial", sans-serif;
}

.workshopDetails_part1_imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    /* height: 200px; */
    margin: 20px auto;
}

.workshopDetails_part1_imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.workshopDetails_part1_imageContainer>div>iframe {
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
    margin-top: 30px;
}

.workshopDetails_part1_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 10px;
}

.workshopDetails_part1_text>h3 {
    margin: 2px 0px;
}

.workshopDetails_part1_text>p {
    margin: 5px 0px;
    font-size: 12px;
}

.workshopDetails_part1_icon_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex: 0 1 calc(50% - 5px);
    margin: 10px 0px;
}

.workshopDetails_part1_icon_details h4 {
    margin: 0px;
}

.shimmer-btn {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(45deg, rgb(96, 64, 238), rgb(120, 80, 255));
    border: none;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 12px;
    transition: transform 0.3s ease;
}

.shimmer-btn:hover {
    transform: scale(1.02);
}

.shimmer-btn::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.5) 50%,
            hsla(21, 63%, 73%, 0));
    transform: rotate(60deg) translate(-5em, 7.5em);
    animation: moveAfter 2s linear infinite;
}

.dashboard-btn {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(45deg, rgb(83, 34, 139), rgb(132, 14, 243));
    /* Green gradient */
    border: none;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 12px;
    transition: background 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-btn:hover {
    background: linear-gradient(45deg, rgb(73, 28, 115), rgb(73, 40, 165));
    /* Darker green on hover */
    transform: scale(1.02);
}

/* Style the arrow icon */
.arrow-icon {
    margin-left: 8px;
    /* Space between text and arrow */
    font-size: 18px;
    /* Adjust size for text arrow */
    /* For SVG */
    vertical-align: middle;
}

.learning_list {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0;
}

.learning_list li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    color: rgb(189 189 189);
    line-height: 1.5;
    font-size: 16px;
}

.learning_list li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #da7eff;
    font-weight: bold;
}

.stat_item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stat_item h3 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.5rem;
}

.info-icon {
    position: relative;
    margin-left: 8px;
    cursor: pointer;
    font-size: 1rem;
}

.tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    width: max-content;
    max-width: 250px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;
    font-size: 12px;
    line-height: 18px;
}

.info-icon:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.stat_item p {
    margin: 4px 0 0 0;
    color: #666;
}

.stat_item p s {
    color: #999;
}

@media screen and (min-width: 768px) {
    .workshopdetails_mobile {
        display: none;
    }

    .workshopdetails_desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 40px;
        color: #fff;
    }

    .desktop_left_column {
        padding: 20px 40px;
    }

    .desktop_right_column {
        padding: 20px 0px;
    }

    .workshopDetails_part1_imageContainer {
        width: 560px;
        height: 250px;
        border-radius: 12px;
        margin: 0px;
    }

    .workshopDetails_part1_imageContainer img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }

    .workshopDetails_part1_imageContainer>div>iframe {
        max-width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 12px;
        margin-top: 30px;

    }

    .desktop_course_info {
        margin-top: 30px;
    }

    .desktop_course_info h2 {
        font-size: 36px;
        margin: 0 0 15px 0;
    }

    .course_description {
        color: rgb(189 189 189);
        line-height: 1.6;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .desktop_stats_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-bottom: 30px;
    }

    .stat_item {
        text-align: center;
    }

    .stat_item h3 {
        font-size: 24px;
        margin: 0;
    }

    .stat_item p {
        color: rgb(189 189 189);
        margin: 5px 0 0;
    }

    .desktop_features_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .feature_item {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .feature_item h4 {
        margin: 0;
        font-size: 16px;
    }

    .desktop_accordion_section .MuiAccordion-root {
        background-color: #e2e2e2;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .desktop_accordion_section .MuiAccordionSummary-root {
        font-size: 18px;
        color: #222;
        font-weight: 800;
    }

    .desktop_certification_section {
        margin-top: 40px;
    }

    .desktop_certification_section h2 {
        font-size: 28px;
        margin: 10px 0;
    }

    .desktop_certification_section p {
        color: rgb(189 189 189);
        line-height: 1.6;
    }

    .desktop_certification_section img {
        width: 100%;
        height: 300px;
        max-width: 500px;
        margin-top: 20px;
        border-radius: 8px;
        object-fit: contain;
    }

    .desktop_learning_outcomes {
        background: rgba(255, 255, 255, 0.05);
        padding: 25px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin: 40px 0px;
    }

    .desktop_learning_outcomes h3 {
        font-size: 24px;
        margin: 0 0 20px 0;
        color: #fff;
    }

    .learning_list {
        list-style: none;
        padding: 0;
        margin: 0 0 25px 0;
    }

    .learning_list li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 15px;
        color: rgb(189 189 189);
        line-height: 1.5;
        font-size: 16px;
    }

    .learning_list li::before {
        content: "✓";
        position: absolute;
        left: 0;
        color: #da7eff;
        font-weight: bold;
    }

    .desktop_full_width_section {
        grid-column: 1 / -1;
        padding: 40px 0;
    }

    .college_presence {
        text-align: center;
        margin-bottom: 60px;
    }

    .faqs_section {
        text-align: center;
        padding: 0px 100px;
    }

    .faq_accordion {
        background-color: rgb(63 40 112);
        border-radius: 8px;
        margin-bottom: 20px;
        text-align: start;
    }

    .faq_accordion .MuiAccordionSummary-root {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }

    .faq_accordion .MuiAccordionDetails-root h4 {
        color: #fff;
        line-height: 1.6;
        margin: 0px;
    }


}
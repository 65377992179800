.certificate_education_circle {
    margin-top: 30px;
    /* position: absolute; */
}

.certificate_education_circle>img {
    width: 80px;
}

.certificate_top_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 40px 60px 40px 10px;
}

.certificate_page1_right {
    box-sizing: border-box;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cert_page1_image_container {
    box-sizing: border-box;
    width: 280px;
    height: 350px;
}

.cert_page1_image_container>img {
    width: 100%;
    height: 100%;
}

.cert_page1_image_details {
    box-sizing: border-box;
    width: 300px;
}

.cert_page1_image_details h1 {
    font-family: "Anton";
    font-style: italic;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
}

.cert_page1_image_details p {
    font-family: "Questrial";
    font-style: italic;
    font-size: 14px;
    font-weight: 600;
    text-align: end;
}

.certificates_headline_container {
    margin-left: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    max-width: 600px;
}

.certificates_headline>h1 {
    font-size: 3rem;
    margin: 0px 0px 0px 0px;
    text-align: center;

}

.certificates_headline>p {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
    font-family: "Questrial";
    font-weight: 300;
}

.certificate_cursor {
    font-size: 5rem;
    background-image: #fff;
    /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #fff;
    font-weight: 600;
}

.certificate_typewriter {
    font-size: 5rem;
    background-image: #fff;
    /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #fff;
    font-weight: 800;
    color: #fff;
}

.certificate_headline_buttonContainer {
    display: flex;
    align-items: center;
}

.certificate_headline_button>button {
    background: #222 !important;
    color: #fff !important;
    font-size: 22px !important;
    padding: 8px !important;
    width: 250px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    font-family: "Questrial";
    margin-right: 30px;
}

.certificates_getIcon_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    margin: 0px 50px;
}

.certificates_getIcon_container>img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificates_getIcon_container>h4 {
    text-align: center;
    font-size: 16px;
    margin: 0px;
}

.certificates_getBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 52px;
    padding: 30px;
    width: 70%;
    margin: auto;
    box-shadow: 0px 4px 10px rgba(246, 246, 247, 0.151), 0 1px 10px rgba(255, 255, 255, 0.11);
    background-color: black;
    color: #fff;
}

.levelCertificates_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-top: 60px;
}

.levelCertificates_main_container>div>h1 {
    font-size: 2.5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36);
    /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.selected_level {
    background: #222 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 52px !important;
    font-weight: 500 !important;
    margin-right: 30px !important;
}

.button_unselected_container {
    border: 1px solid #222 !important;
    color: #222 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 52px !important;
    font-weight: 500 !important;
    margin-right: 30px !important;
}

.getCertificationContainer_main {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(250, 57, 73, 0.13), 0 1px 10px rgba(255, 75, 90, 0.075);
    border-radius: 22px;
    margin-top: 40px;
    padding: 30px 40px;
}

.certificates_image_container {
    background-color: #ff3d3d17;
    padding: 30px;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;


}

.certificates_image_container>img {
    width: 300px;
    height: 250px;
    object-fit: cover;
}

.certificates_points_container {
    display: flex;
    flex-direction: column;

}

.certificates_points_details_main_container {
    margin-left: 40px;
    /* margin-top: 40px; */
}

.certificates_points_details_main_container>h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.certificates_points_enroll_button {
    margin-top: 20px;
}

.certificates_points_enroll_button>button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    margin-right: 30px !important;
}

.certificates_acknowledgements_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 0px 10px 0px;
}

.certificates_acknowledgements_container>h1 {
    font-size: 2.5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36);
    /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.certification_line {
    width: 40%;
    flex: 1;
}

.certificate_card {
    background-color: #fff;
    width: 300px;
    height: 400px;
    color: #222;
    font-family: "Questrial";
    border-radius: 22px;
    margin: 10px
}

.enroll_button {
    width: 100% !important;
    text-transform: inherit !important;
    background-color: rgb(96, 64, 238) !important;
    color: #fff !important;
    border-radius: 22px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: "Questrial" !important;
}

.certification_page2_card::-webkit-scrollbar {
    display: none;
}

.view_all_certification:hover {
    text-decoration: underline;
}

.college_certification_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.college_certification_button>button {
    color: #fff !important;
    text-transform: inherit !important;
    font-size: 22px !important;
    font-family: 900 !important;
    font-family: "Questrial" !important;
    background-color: #8255fc !important;
    padding: 10px 30px !important;
    border-radius: 42px !important;
    width: 300px !important;
    margin: 20px 0px !important;
}

.certification_ambassador_images {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certification_ambassador_images img {
    width: 200px;
    height: 250px;
    object-fit: cover;
}

.ambassador_join_button>button {
    color: #fff !important;
    text-transform: inherit !important;
    font-size: 22px !important;
    font-weight: 900 !important;
    font-family: "Questrial" !important;
    background-color: #8255fc !important;
    padding: 10px 30px !important;
    border-radius: 42px !important;
    width: 250px !important;
    margin: 20px 0px !important;
}

.footer_send_button {
    color: #fff;
    text-transform: inherit;
    font-size: 18px;
    font-family: "Questrial";
    background-color: #8255fc;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
}

.footer_content p {
    margin: 25px 0px;
    font-size: 18px;
    cursor: pointer;
}
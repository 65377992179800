/* DashboardWorkshop.css */
.dashboard-container {
    min-height: 100vh;
    background-color: #000414;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    background-size: 20px 20px;
    font-family: "Questrial", sans-serif;
    color: #fff;
}

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    /* Purple border for accent */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    /* More padding for breathing room */
    backdrop-filter: blur(18px) saturate(116%);
    -webkit-backdrop-filter: blur(18px) saturate(116%);
    background-color: rgba(246, 253, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 1000;
}

.top-bar-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.top-bar-left h2 {
    margin: 0;
    color: rgb(96, 64, 238);
    font-size: 1.5rem;
}

.company-branding {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.company-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* Circular logo */
    object-fit: contain;
}

.company-name {
    font-size: 1.0rem;
    font-weight: 600;
    color: #ffffff;
    margin-top: 20px;
    margin-left: -10px;
}

.top-bar-right {
    position: relative;
    /* For positioning the user menu */
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 15px;
    border-radius: 22px;
    cursor: pointer;
    transition: all 0.3s;
}

.user-profile:hover {
    background: rgba(96, 64, 238, 0.2);
}

.user-avatar {
    width: 32px;
    height: 32px;
}


.user-name {
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 500;
}

.user-menu-popover {
    background: #263544 !important;
    border: 1px solid #34495e !important;
    border-radius: 6px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.menu-item {
    color: #d6deeb !important;
    padding: 8px 15px !important;
    font-size: 0.95rem !important;
    text-transform: none !important;
    justify-content: flex-start !important;
    transition: all 0.3s !important;
}

.menu-item:hover {
    background: rgba(96, 64, 238, 0.2) !important;
    color: rgb(96, 64, 238) !important;
}

.menu-item.logout {
    border-top: 1px solid #34495e !important;
    margin-top: 5px !important;
    color: #e74c3c !important;
}

.menu-item.logout:hover {
    background: rgba(231, 76, 60, 0.2) !important;
    color: #e74c3c !important;
}

.mobile-menu-btn {
    display: none;
    /* Will show on mobile via media query */
    background: rgb(96, 64, 238);
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 1.2rem;
    cursor: pointer;
}

.notification-btn,
.settings-btn {
    background: none;
    border: none;
    color: #95a5a6;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s;
}

.notification-btn:hover,
.settings-btn:hover {
    color: rgb(96, 64, 238);
}

.sidebar {
    width: 260px;

    padding: 20px;
    transition: transform 0.3s ease;
    position: fixed;
    top: 60px;
    bottom: 0;
    z-index: 10;
    backdrop-filter: blur(18px) saturate(116%);
    -webkit-backdrop-filter: blur(18px) saturate(116%);
    background-color: rgba(246, 253, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sidebar-header h2 {
    color: rgb(96, 64, 238);
    margin: 0;
}

.nav-item {
    display: block;
    padding: 10px 15px;
    margin: 5px 0;
    color: #d6deeb;
    background: none;
    border: none;
    border-radius: 6px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s;
}

.nav-item:hover,
.nav-item.active {
    background: rgb(96, 64, 238);
    color: #ffffff;
}

.main-content {
    flex: 1;
    padding: 80px 40px 40px;
    margin-left: 260px;
}

/* Bootcamp Card Styles */
.bootcamp-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px 30px;
    position: relative;
    min-height: 50vh;
}

.bootcamp-grid:empty,
.bootcamp-grid .no-bootcamps {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #666;
}

.workshop-card {
    background: #263544;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #34495e;
    cursor: pointer;
    box-shadow: none;
}

.workshop-card:hover {
    background: #2c3e50;
    border-color: rgb(96, 64, 238);
    box-shadow: none;
}

.card-image-container {
    position: relative;
    width: 100%;
    height: 200px;
}

.card-workshop-youtube-container>div>iframe {
    max-width: 100% !important;
    height: 200px !important;
}

.workshop-image {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.date-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.calendar-icon {
    color: rgb(96, 64, 238);
}

.date-text {
    margin: 0;
    font-size: 0.9rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.level-tag {
    background: rgb(96, 64, 238);
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.85rem;
}

.spots-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.groups-icon {
    color: #d6deeb;
}

.spots-text {
    margin: 0;
    font-size: 0.9rem;
    color: #d6deeb;
}

.workshop-title {
    font-size: 1.2rem;
    margin: 0 0 10px;
    color: #ffffff;
}

.workshop-desc {
    color: #d6deeb;
    margin: 0 0 10px;
    font-size: 0.9rem;
}

.registered-preview {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.avatar-overlap {
    margin-left: -8px;
}

.registered-count {
    margin: 0;
    font-size: 0.9rem;
    color: #95a5a6;
}

.card-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
}

.select-time-btn {
    background: rgb(96, 64, 238);
    color: #ffffff;
    border: none;
    padding: 8px 15px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
}

.select-time-btn:hover {
    background: rgb(74, 50, 184);
}

.book-time-btn.full-width {
    width: 100%;
    background: rgb(96, 64, 238);
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-time-btn.full-width:hover {
    background: rgb(74, 50, 184);
}

.book-time-btn:hover {
    background: rgb(74, 50, 184);
}

.book-time-btn.full-width.unlocked {
    background: linear-gradient(90deg, #ffae00, #FFA500);
    /* Gold color for premium users */
    color: #1a1a2e;
    font-weight: 700;
    /* Dark text for contrast against gold */
}

.book-time-btn.full-width.unlocked:hover {
    background: linear-gradient(90deg, #ffbb00, #FFA500);
    /* Slightly darker gold on hover */
}

.live-session-btn {
    background: linear-gradient(45deg, #ff5252, #ff1744);
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    animation: pulse 1.5s infinite;
    width: 100%;
    justify-content: center;
}

.live-session-btn:hover {
    background: linear-gradient(45deg, #ff1744, #d81b60);
}

.live-dot {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    animation: pulse-dot 1.5s infinite alternate;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

.registered-btn {
    background: #34495e;
    color: #d6deeb;
    border: none;
    padding: 8px 15px;
    border-radius: 6px;
    cursor: not-allowed;
}

.enrolled-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(39, 174, 96, 0.9);
    /* Green color for enrolled status */
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 500;
}

/* Drawer Styles */
.workshop-drawer .MuiDrawer-paper {
    background: #000414;
    color: #d6deeb;
    width: 100%;
    max-width: 500px;
    border-left: 2px solid rgba(96, 64, 238, 0.562);
    box-shadow: 0 0 10px rgba(96, 64, 238, 0.2);
}

/* Enhanced Accordion Styles */
.workshop-accordion {
    background: #263544;
    /* border: 1px solid #34495e; */
    margin-bottom: 10px;
    color: #d6deeb;
    transition: all 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.workshop-accordion:hover {
    /* border-color: rgb(96, 64, 238); */
    box-shadow: 0 4px 8px rgba(96, 64, 238, 0.2);
}

.workshop-accordion.Mui-expanded {
    background: #2c3e50;
    /* border-color: rgb(96, 64, 238); */
    box-shadow: 0 4px 8px rgba(96, 64, 238, 0.2);
}

.workshop-accordion .MuiAccordionSummary-root {
    padding: 0 15px;
    min-height: 56px;
    transition: background 0.3s;
}

.workshop-accordion .MuiAccordionSummary-root:hover {
    background: rgba(96, 64, 238, 0.1);
}

.workshop-accordion .MuiSvgIcon-root {
    transition: color 0.3s ease;
    /* Smooth color transition */
}

.workshop-accordion .MuiAccordionSummary-root:hover .MuiSvgIcon-root {
    color: #d6deeb;
    /* Brighten icon on hover */
}

.workshop-accordion .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #ffffff;
}

.workshop-accordion .MuiAccordionDetails-root {
    padding: 15px;
    background: #34495e;
    color: #d6deeb;
    line-height: 1.6;
    border-top: 1px solid #34495e;
}

/* Enhanced Form Control Styles */
.workshop-form-control {
    margin: 10px 0 !important;
    position: relative;
}

.workshop-form-control .MuiInputLabel-root {
    color: #d6deeb;
    font-weight: 500;
    transition: all 0.3s;
}

.workshop-form-control .MuiInputLabel-root.Mui-focused {
    color: rgb(96, 64, 238);
    /* Purple when focused */
}

/* Select input styling */
.workshop-form-control .MuiSelect-root {
    color: #d6deeb;
    background: #263544;
    border-radius: 8px;
    /* Slightly more rounded */
    padding: 12px 36px 12px 16px;
    /* Adjusted padding for better spacing with icon */
    border: 1px solid #34495e;
    transition: all 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.workshop-form-control .MuiSelect-select.MuiSelect-select {
    color: #ffffff;
    /* White color for selected text */
}

/* Hover state */
.workshop-form-control .MuiSelect-root:hover {
    border-color: rgb(96, 64, 238);
    box-shadow: 0 4px 8px rgba(96, 64, 238, 0.2);
}

/* Focused state */
.workshop-form-control .MuiOutlinedInput-root.Mui-focused .MuiSelect-root {
    background: #2c3e50;
    /* Slightly darker when focused */
    border-color: rgb(96, 64, 238);
    box-shadow: 0 0 8px rgba(96, 64, 238, 0.3);
}

/* Outline styling */
.workshop-form-control .MuiOutlinedInput-notchedOutline {
    border-color: #34495e;
    transition: all 0.3s;
}

.workshop-form-control .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(96, 64, 238);
}

.workshop-form-control .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(96, 64, 238);
    border-width: 2px;
    /* Slightly thicker when focused */
}

/* Dropdown icon */
.workshop-form-control .MuiSvgIcon-root {
    color: #d6deeb;
    right: 8px;
    /* Closer to edge */
    transition: all 0.3s;
}

.workshop-form-control .MuiSelect-root.Mui-focused+.MuiSvgIcon-root {
    color: rgb(96, 64, 238);
    /* Purple when focused */
}

/* Menu (dropdown) styling */
.workshop-form-control .MuiMenu-paper {
    background: #263544;
    border: 1px solid #34495e;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    margin-top: 4px;
}

/* Menu Item Styles */
.workshop-form-control .MuiMenuItem-root {
    background: #263544;
    color: #d6deeb;
    padding: 12px 16px;
    /* More padding for better touch target */
    transition: all 0.3s;
}

.workshop-form-control .MuiMenuItem-root:hover {
    background: #2c3e50;
    color: rgb(96, 64, 238);
    box-shadow: inset 0 0 5px rgba(96, 64, 238, 0.2);
}

.workshop-form-control .MuiMenuItem-root.Mui-selected {
    background: rgba(96, 64, 238, 0.1);
    color: rgb(96, 64, 238);
    font-weight: 500;
}

.workshop-form-control .MuiMenuItem-root.Mui-selected:hover {
    background: rgba(96, 64, 238, 0.2);
}

/* Button Styles */
.workshop-button {
    border-radius: 6px;
    padding: 8px 20px;
    transition: all 0.3s;
}

.workshop-button.purple {
    background: rgb(96, 64, 238);
    color: #ffffff;
}

.workshop-button.purple:hover {
    background: rgb(74, 50, 184);
}

.workshop-button.MuiButton-outlined {
    background: transparent;
    color: rgb(96, 64, 238);
    border-color: rgb(96, 64, 238);
}

/* Tabs Styles */
.workshop-tabs .MuiTabs-indicator {
    background-color: rgb(96, 64, 238);
}

.workshop-tabs .MuiTab-root {
    color: #d6deeb;
    font-weight: 500;
}

.workshop-tabs .MuiTab-root:hover,
.workshop-tabs .MuiTab-root.Mui-selected {
    color: rgb(96, 64, 238);
}

/* Miscellaneous */
.original-price {
    color: #95a5a6;
    margin-top: 10px;
}

.learning-list {
    list-style: none;
    margin-left: 20px;
    margin-bottom: 15px;
    color: #d6deeb;
    padding-left: 20px;
    padding-bottom: 10px;
}

.learning-list li {
    position: relative;
    /* Make li the relative parent for the absolute ::before */
    padding-left: 20px;
    /* Space for the checkmark */
    line-height: 1.6;
    margin: 10px 0px;
}

.learning-list li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #da7eff;
    /* Light purple color */
    font-weight: bold;
}



.info-grid {
    display: grid;
    gap: 15px;
    margin-bottom: 20px;
}

.support-content textarea {
    width: 80%;
    min-height: 100px;
    margin: 20px 0;
    padding: 15px;
    background: #263544;
    border: 1px solid #34495e;
    border-radius: 8px;
    color: #d6deeb;
    resize: vertical;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile Menu */
.mobile-menu-btn,
.close-btn {
    display: none;
}

.selected-time-slot {
    margin: 10px 0px 20px 0px;
    padding: 10px 15px;
    background: rgba(96, 64, 238, 0.1);
    /* Subtle purple background */
    border: 1px solid rgb(96, 64, 238);
    border-radius: 6px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 6px rgba(96, 64, 238, 0.2);
    transition: all 0.3s;
}

/* Hover effect for interactivity */
.selected-time-slot:hover {
    background: rgba(96, 64, 238, 0.15);
    box-shadow: 0 4px 8px rgba(96, 64, 238, 0.25);
}

.confirm {
    background-color: #4caf50 !important;
    color: white !important;
    font-weight: bold;
    padding: 2px 8px !important;
    border-radius: 8px;
    height: 30px;
    text-transform: inherit !important;
    border-radius: 12px !important;
}

.confirm:hover {
    background-color: #388e3c !important;
}

.cancel {
    border: 1px solid #f44336 !important;
    color: white !important;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 12px !important;
    height: 30px;
    text-transform: inherit !important;
}

.cancel:hover {
    background-color: #d32f2f !important;
}

/* LiveSessionModal.css */

.ai-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    background: linear-gradient(145deg, #1e1e38, #252550);
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(78, 78, 130, 0.2);
    color: #ffffff;
    animation: fadeIn 0.3s ease-in-out;

}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -45%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.ai-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(78, 78, 130, 0.3);
    padding-bottom: 10px;
}

.ai-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.5px;
}

.ai-modal-close {
    color: #b0b0cc;
    transition: all 0.2s ease;
    position: absolute;
    top: -25px;
    right: -20px;
}

.ai-modal-close:hover {
    color: #ffffff;
    transform: rotate(90deg);
}

.ai-modal-content {
    text-align: left;
}

.ai-modal-details {
    font-size: 15px;
    margin-bottom: 25px;
    color: #d0d0e0;
    font-family: "Questrial", sans-serif;
}

.time-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Reduced to make space for the message */
    background: rgba(76, 175, 80, 0.1);
    padding: 8px 12px;
    border-radius: 6px;
}

.activation-message {
    font-size: 13px;
    color: #b0b0cc;
    margin-bottom: 15px;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 6px;
    text-align: center;
}

.ai-modal-link-container {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 10px 12px;
    border: 1px solid rgba(78, 78, 130, 0.3);
    transition: all 0.2s ease;
}

.ai-modal-link-container:hover {
    background: rgba(255, 255, 255, 0.1);
}

.ai-modal-link {
    flex-grow: 1;
    font-size: 14px;
    color: #4285F4;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blurred-link {
    color: #b0b0cc;
    filter: blur(2px);
    pointer-events: none;
}

.ai-modal-join-btn {
    background: #e74c3c;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(66, 133, 244, 0.3);
}

.ai-modal-join-btn:disabled {
    background: #4a4a6a;
    opacity: 0.7;
    cursor: not-allowed;
    box-shadow: none;
}

.ai-modal-join-btn:not(:disabled):hover {
    background: #f84633;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(66, 133, 244, 0.4);
}

.unlock-message {
    background: rgba(39, 174, 96, 0.1);
    /* Subtle green background */
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    animation: pulseGlow 2s infinite ease-in-out;
}

@keyframes pulseGlow {
    0% {
        box-shadow: 0 0 5px rgba(39, 174, 96, 0.2);
    }

    50% {
        box-shadow: 0 0 15px rgba(39, 174, 96, 0.5);
    }

    100% {
        box-shadow: 0 0 5px rgba(39, 174, 96, 0.2);
    }
}

/* Pay Button Styling and Animation */

.animated-pay-button {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(45deg, rgb(96, 64, 238), rgb(120, 80, 255));
    border: none;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 12px;
    transition: transform 0.3s ease;
}

.animated-pay-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(245, 29, 29, 0.4);
    /* Purple shadow for hover */
}

.animated-pay-button::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.5) 50%,
            hsla(21, 63%, 73%, 0));
    transform: rotate(60deg) translate(-5em, 7.5em);
    animation: moveAfter 2s linear infinite;
}



/* Ensure the button retains its purple theme */
.workshop-button.purple {
    background: linear-gradient(45deg, #ff5252, #ff1744);
    /* Purple gradient */
    color: #ffffff;
}

.workshop-button.purple:hover {
    background: linear-gradient(45deg, #ff1744, #d81b60);
}

.premium-card {
    border: 1px solid #FFD700;
    background: linear-gradient(135deg, #1a1a2e, #2e2e4d);
    /* Subtle premium gradient */
}

.premium-card:hover {
    /* transform: scale(1.03); */
    box-shadow: none;
    border: 1px solid #FFD700;
}

.premium-badge {
    background: linear-gradient(90deg, #FFD700, #FFA500);
    /* Gold to orange */
    color: #1a1a2e;
    font-weight: bold;
}

/* Premium unlocked styling for paid users */
.workshop-accordion .MuiAccordionSummary-root.premium-unlocked {
    background: linear-gradient(90deg, #1a202c, #2e2e4d);
    border: 1px solid #FFD700;
}

.workshop-accordion .MuiAccordionSummary-root.premium-unlocked:hover {
    background: linear-gradient(90deg, #252c3a, #35355a);
}

.completed-btn {
    background-color: #4CAF50;
    /* Green to indicate completion */
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: not-allowed;
    /* Disabled cursor */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.completed-btn:hover {
    background-color: #45a049;
    /* Slightly darker green on hover */
}

.full-width {
    width: 100%;
}

/* Golden icon animation */
@keyframes goldenPulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}

.workshop-accordion .MuiSvgIcon-root.premium-icon {
    color: #FFD700;
    /* Golden color */
    animation: goldenPulse 2s infinite ease-in-out;
}

.panel {
    background: #263544;
    border: 1px solid #34495e;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.panel:hover {
    border-color: rgb(96, 64, 238);
    box-shadow: 0 4px 12px rgba(96, 64, 238, 0.2);
}

.panel h2 {
    margin: 0 0 20px;
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 600;
    border-bottom: 1px solid #34495e;
    padding-bottom: 10px;
}

.support-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.support-text {
    color: #d1d5db;
    /* Light gray for readability */
    font-size: 1rem;
    margin: 0;
    line-height: 1.4;
}

.support-contact {
    color: #9ca3af;
    /* Slightly darker gray */
    font-size: 1rem;
    margin: 0;
    line-height: 1.4;
}

.email-link {
    color: rgb(96, 64, 238);
    /* Purple to match your theme */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.email-link:hover {
    color: rgb(124, 77, 255);
    /* Lighter purple on hover */
    text-decoration: underline;
}

.profile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

.info-grid div {
    background: #34495e;
    padding: 12px;
    border-radius: 6px;
    transition: all 0.3s ease;
    color: #d6deeb;
}

.info-grid div:hover {
    background: #2c3e50;
    box-shadow: 0 2px 6px rgba(96, 64, 238, 0.1);
}

.info-grid strong {
    font-size: 0.9rem;
    color: #95a5a6;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 5px;
}

.info-grid div span:not(strong) {
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 400;
}

/* Button Styling (Already in your CSS, just ensuring consistency) */
.profile-button.purple {
    background: rgb(96, 64, 238);
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 6px;
    transition: all 0.3s;
    width: 300px !important;
}

.profile-button.purple:hover {
    background: rgb(74, 50, 184);
}

.payment-container {
    max-width: 1200px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
}

.payment-header {
    text-align: center;
    margin-bottom: 1rem;
}

.payment-header h2 {
    color: #fff;
    font-size: clamp(1.2rem, 4vw, 1.5rem);
    margin-bottom: 0.25rem;
}

.payment-header p {
    color: #888;
    font-size: clamp(0.8rem, 3vw, 0.9rem);
}

.payment-list {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap to next line */
    gap: 1rem;
    justify-content: start;
    margin: 0px 20px;
    /* Center the cards */
}

.payment-card {
    background: #263544;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    padding: clamp(0.75rem, 2vw, 1rem);
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    gap: 0.5rem;
    width: 100%;
    /* Default to full width */
    max-width: 320px;
    /* Cap at slightly above 300px */
    min-width: 280px;
    /* Minimum width */
    flex: 1 1 300px;
    /* Flex grow, shrink, and basis targeting ~300px */
}

.payment-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.payment-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 100%;
}

.payment-title h3 {
    margin: 0;
    font-size: clamp(0.9rem, 3vw, 1rem);
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.status-badge {
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
    font-size: clamp(0.6rem, 2vw, 0.7rem);
    font-weight: 500;
    white-space: nowrap;
}

.status-badge.success {
    background: #e6ffe6;
    color: #006600;
}

.status-badge.pending {
    background: #fff3e6;
    color: #cc6600;
}

.payment-date {
    color: #d1d5db;
    font-size: clamp(0.7rem, 2.5vw, 0.8rem);
}

.payment-details {
    display: flex;
    flex-direction: column;
    /* Stack details vertically */
    gap: 0.3rem;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    font-size: clamp(0.75rem, 2.5vw, 0.85rem);
    flex-wrap: wrap;
    gap: 0.25rem;
}

.detail-label {
    color: #9ca3af;
    font-weight: 500;
}

.detail-value {
    color: #ffffff;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.payment-media {
    margin: 0.5rem 0;
}

.media-preview {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
}

.payment-image {
    width: clamp(50px, 15vw, 60px);
    height: clamp(50px, 15vw, 60px);
    border-radius: 4px;
    object-fit: cover;
}

.media-info {
    flex: 1;
    min-width: 0;
}

.media-title {
    margin: 0;
    font-size: clamp(0.8rem, 2.5vw, 0.9rem);
    color: #ffffff;
    font-weight: 500;
}

.media-description {
    margin: 0.5rem 0 0;
    color: #d1d5db;
    font-size: clamp(0.65rem, 2vw, 0.75rem);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.invoice-button {
    background: #6200ea;
    color: white;
    border: none;
    padding: clamp(0.4rem, 1.5vw, 0.5rem) clamp(0.8rem, 3vw, 1rem);
    border-radius: 4px;
    cursor: pointer;
    font-size: clamp(0.75rem, 2.5vw, 0.85rem);
    font-weight: 500;
    transition: background 0.2s;
    margin-top: auto;
    /* Push button to bottom */
}

.invoice-button:hover {
    background: #7c4dff;
}

.no-payments {
    text-align: center;
    padding: clamp(1rem, 5vw, 2rem);
    background: #263544;
    border-radius: 6px;
}

.no-payments h3 {
    font-size: clamp(1rem, 3.5vw, 1.2rem);
    margin-bottom: 0.5rem;
}

.no-payments p {
    font-size: clamp(0.8rem, 3vw, 0.9rem);
    color: #888;
}


/* Responsive Design */
@media (max-width: 1024px) {
    .main-content {
        margin-left: 0;
        padding: 80px 20px 20px;
    }

    .sidebar {
        transform: translateX(-100%);
        width: 260px;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .mobile-menu-btn {
        display: block;
        background: rgb(96, 64, 238);
        color: #ffffff;
        border: none;
        padding: 8px 12px;
        border-radius: 6px;
        font-size: 1.2rem;
        cursor: pointer;
    }

    .close-btn {
        display: block;
        background: none;
        border: none;
        color: #d6deeb;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .top-bar-left h2 {
        display: none;
    }

    .top-bar {
        padding: 0 15px;
    }

    .mobile-menu-btn {
        display: block;
    }

    .company-name {
        font-size: 0.8rem;
    }

    .user-name {
        font-size: 1rem;
        padding: 6px 12px;
    }
}

@media (max-width: 480px) {
    .bootcamp-grid {
        grid-template-columns: 1fr;
    }

    .workshop-card {
        padding: 10px;
    }

    .workshop-title {
        font-size: 1rem;
    }

    .workshop-desc {
        font-size: 0.85rem;
    }

    .card-footer {
        flex-direction: column;
        gap: 5px;
    }

    .select-time-btn,
    .book-time-btn,
    .registered-btn {
        width: 100%;
    }

    .workshop-drawer .MuiDrawer-paper {
        max-width: 100%;
        max-height: 90vh;
        min-height: 80vh;
        border-left: none;
        border-top: 2px solid rgb(96, 64, 238);
        border-radius: 8px 8px 0 0;
    }

    .selected-time-slot {
        padding: 8px 12px;
        font-size: 0.9rem;
    }

    .selected-time-slot .MuiSvgIcon-root {
        font-size: 1.1rem;
        /* Slightly smaller icon on mobile */
    }

    .top-bar {
        height: 60px;
    }

    .company-logo {
        width: 32px;
        height: 32px;
    }

    .company-name {
        font-size: 0.7rem;
    }

    .user-name {
        font-size: 0.9rem;
    }

    .user-avatar {
        width: 24px;
        height: 24px;
    }

    .user-profile {
        padding: 5px 10px;
    }

    .menu-item {
        font-size: 0.85rem !important;
        padding: 6px 12px !important;
    }

    .desktop-only {
        display: none;
        /* Hide first name on mobile */
    }

    .ai-modal {
        width: 300px;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .panel {
        padding: 15px;
    }

    .panel h2 {
        font-size: 1.3rem;
    }

    .profile-button.purple {
        width: 100%;
    }

    .payment-list {
        gap: 0.75rem;
        /* Slightly smaller gap */
    }

    .payment-card {
        flex: 1 1 300px;
        /* Maintain ~300px width */
    }

    .payment-list {
        justify-content: center;
        /* Keep centered */
    }

    .payment-card {
        max-width: 100%;
        /* Full width on mobile */
        min-width: 0;
        /* Allow shrinking */
        flex: 1 1 100%;
        /* Take full width on small screens */
    }

    .payment-card-header {
        align-items: flex-start;
    }

    .detail-item {
        gap: 2rem;
    }

    .detail-value {
        max-width: 100%;
    }

    .media-preview {
        flex-direction: column;
        align-items: flex-start;
    }

    .support-text,
    .support-contact {
        font-size: 0.9rem;
    }
}
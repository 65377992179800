/* Desktop Hero Section */
.desktop-hero {
    padding: 80px 40px 40px;
    max-width: 1400px;
    margin: 0 auto;
    background-color: #000414;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    background-size: 20px 20px;
    position: relative;
    overflow: hidden;
}

/* Split Layout */
.desktop-hero-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 450px;
    gap: 40px;
}

/* Left Side */
.desktop-hero-left {
    flex: 1;
    padding: 0 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.desktop-hero-title {
    font-size: 50px;
    margin: 0 0 15px;
    font-weight: 700;
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.static-text {
    background: none;
    color: #fff;
    -webkit-text-fill-color: #fff;
    margin-right: 10px;
    font-size: 4vw;
}

.fade-text {
    /* Replaced .typing-text */
    background: linear-gradient(45deg, #da7eff, #ff57db);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeInOut 3s infinite;
    font-size: 4vw;
    /* Added fade animation */
}


.desktop-hero-subtitle {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 25px;
    color: #e0e0e0;
    font-weight: 400;
    max-width: 500px;
}

/* CTA Container */
.desktop-cta-container {
    display: flex;
    gap: 15px;
    margin-bottom: 25px;
}

.desktop-cta-btn {
    width: 200px;
    padding: 12px 28px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.desktop-cta-btn.primary {
    background: linear-gradient(45deg, #9f10f1, #ff57db);
    color: #fff;
}

.desktop-cta-btn.primary:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(159, 16, 241, 0.5);
}

.desktop-cta-btn.secondary {
    background: transparent;
    color: #fff;
    border: 2px solid #ff57db;
}

.desktop-cta-btn.secondary:hover {
    background: rgba(255, 87, 219, 0.1);
    transform: scale(1.05);
}

/* Stats Bar */
.desktop-stats-bar {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    color: #d0d0d0;
}

.divider_line {
    color: #ff57db;
    height: 20px;
    background-color: #ff57db;
    width: 2px;
}

/* Right Side: Animated Code Block */
.desktop-hero-right {
    flex: 1;
    position: relative;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-code-block {
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
    width: 100%;
    max-width: 550px;
    height: 250px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
    will-change: transform;
    transform: translateZ(0);
}

.code-content {
    margin: 0;
    font-family: "Courier New", monospace;
    font-size: 16px;
    color: #fff;
    line-height: 1.6;
    white-space: pre;
    height: 100%;
    overflow: hidden;
}

.code-line {
    display: block;
}

/* Green Cursor */
.code-cursor {
    color: #00ff00;
    font-size: 18px;
    margin-left: 2px;
    animation: blink 1s ease-in-out infinite;
    vertical-align: middle;
    display: inline-block;
}

/* Syntax Highlighting */
.code-line:nth-child(1) {
    color: #ff79c6;
}

.code-line:nth-child(2) {
    color: #ff79c6;
}

.code-line:nth-child(4) {
    color: #8be9fd;
}

.code-line:nth-child(5) {
    color: #8be9fd;
}

.code-line:nth-child(6) {
    color: #8be9fd;
}

.code-line:nth-child(7) {
    color: #50fa7b;
}

.code-line:nth-child(10) {
    color: #bd93f9;
}

.code-line:nth-child(17) {
    color: #ff79c6;
}

/* Companies Marquee */
.desktop-companies-marquee {
    margin: 20px auto;
    max-width: 1200px;
}

/* Scroll Indicator */
.scroll-indicator {
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding: 20px 0;
}

.scroll-arrow {
    font-size: 20px;
    margin-top: 5px;
    animation: bounce 1.5s infinite;
}

/* Animations */
@keyframes blink {
    0%, 100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-8px);
    }

    60% {
        transform: translateY(-4px);
    }
}

@keyframes fadeInOut {

    /* Added fade animation */
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Adjust desktop-workshop-part2 for peek */
.desktop-workshop-part2 {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    top: -60px;
}
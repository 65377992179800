/* Workshop.css */
/* Common Styles */
.workshop_main {
    background-color: #000414;
    background-image: radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px);
    background-size: 20px 20px;
    font-family: "Questrial", sans-serif;
    color: #fff;
}


.mobile-part1 {
    color: #fff;
    padding: 100px 20px 0px 20px;

}

.mobile-hero-title {
    font-size: 45px;
}

.mobile-gradient-text {
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    margin: 2px 0;
}

.mobile-workshop-subtitle {
    background: linear-gradient(45deg, #161616, #0f0e0e, #252525, #0c0b0a);
    padding: 5px 20px;
    border-radius: 12px;
    margin: 20px 0 30px 0px;
    border-left: 1px solid white;
}

.mobile-hero-subtitle {
    line-height: 38px;
    margin: 0;
    font-size: 18px;
}

.mobile-registered-users {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.mobile-avatar-overlap {
    margin-right: -15px;
}

.mobile-registered-text {
    margin-left: 20px;
    font-weight: 700;
    font-size: 20px;
}

.mobile-workshop-part2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.mobile-section-header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mobile-glitch {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(90deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 0 0 10px rgba(218, 126, 255, 0.8),
        0 0 20px rgba(159, 16, 241, 0.6),
        0 0 30px rgba(255, 87, 219, 0.5),
        0 0 40px rgba(63, 13, 247, 0.4);
    animation: hologlow 2s infinite alternate ease-in-out;
}

.mobile-workshops-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.mobile-workshop-card {
    background-color: #fff;
    width: 320px;
    color: #222;
    border-radius: 22px;
    margin: 10px;
}

.mobile-card-image-container {
    position: relative;
}

.mobile-workshop-image {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    object-fit: cover;
}

.mobile-date-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-date-content {
    display: flex;
    align-items: center;
}

.mobile-calendar-icon {
    font-size: 16px;
    margin-bottom: 2px;
}

.mobile-date-text, .mobile-month-text {
    font-size: 12px;
    margin: 2px;
}

.mobile-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.mobile-level-tag {
    border: 1px solid #d9d9d9;
    padding: 5px;
    border-radius: 5px;
    background-color: #f0f0f0;
}

.mobile-spots-info {
    display: flex;
    align-items: center;
}

.mobile-groups-icon {
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.mobile-spots-text {
    font-size: 14px;
}

.mobile-workshop-title {
    margin: 0 10px;
}

.mobile-workshop-desc {
    margin: 10px 10px;
    line-height: 1.5;
}

.mobile-registered-preview {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.mobile-avatar-overlap-small {
    margin-right: -15px;
}

.mobile-registered-count {
    margin-left: 20px;
}

.mobile-card-footer {
    padding: 10px;
    text-align: center;
}

.mobile-shimmer-btn {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: rgb(96, 64, 238);
    border: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.mobile-shimmer-btn::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.5) 50%,
            hsla(21, 63%, 73%, 0));
    transform: rotate(60deg) translate(-5em, 7.5em);
    animation: moveAfter 2s linear infinite;

}

.mobile-shimmer-btn.registered {
    background: rgba(96, 64, 238, 0.5);
    /* More transparent version */
    cursor: not-allowed;
    opacity: 0.7;
    color: rgba(255, 255, 255, 0.8);
    text-transform: inherit !important;
}

.mobile-shimmer-btn.registered::after {
    animation: moveAfter 4s linear infinite;
    /* Slower shimmer for inactive state */
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.2) 50%,
            /* Reduced opacity for shimmer */
            hsla(21, 63%, 73%, 0));
}

@keyframes moveAfter {
    0% {
        transform: rotate(60deg) translate(-5em, 7.5em);
    }

    100% {
        transform: rotate(60deg) translate(5em, -7.5em);
    }
}

.mobile-price-info {
    margin: 5px 0;
}

.mobile-original-price {
    text-decoration: line-through;
}

.mobile-separator {
    margin: 0 5px;
}

.mobile-part3 {
    margin-bottom: 20px;
}

.mobile-workshop-carousal {
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

.mobile-colleges-section {
    margin-top: 60px;
    text-align: center;
    color: #fff;
}

.mobile-section-title {
    color: #fff;
    margin: 5px 30px;
    font-size: 35px;
}

.mobile-section-subtitle {
    color: #bbb6b6;
    margin: 10px 20px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}

.mobile-certificates-section {
    margin-top: 60px;
    text-align: center;
    color: #fff;
}

.mobile-certificate-image {
    width: 300px;
    margin: 10px 0;
}

.mobile-reviews-section {
    margin-top: 60px;
    text-align: center;
    color: #fff;
}

/* Desktop Styles (Enhanced) */
/* Adjust desktop-workshop-part2 for peek */
.desktop-workshop-part2 {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    top: -60px;
}

.desktop-gradient-text {
    background: linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    margin: 5px 0;
    text-shadow: 0 0 20px rgba(218, 126, 255, 0.3);
}

.desktop-workshop-subtitle {
    background: linear-gradient(45deg, #161616, #0f0e0e, #252525, #0c0b0a);
    padding: 20px 30px;
    border-radius: 15px;
    margin: 30px 0;
    border-left: 2px solid white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.desktop-hero-subtitle {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
}

.desktop-registered-users {
    display: flex;
    align-items: center;
    margin: 40px 0;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    border-radius: 12px;
}

.desktop-avatar-overlap {
    margin-right: -25px;
    border: 3px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.desktop-registered-text {
    margin-left: 30px;
    font-weight: 700;
    font-size: 28px;
}

.desktop-workshop-part2, .desktop-part3 {
    max-width: 1400px;
    margin: 80px auto;
    padding: 0 40px;
}

.desktop-section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.desktop-glitch {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(90deg, #da7eff, #9f10f1, #ff57db, #3f0df7);
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 0 0 15px rgba(218, 126, 255, 0.8),
        0 0 25px rgba(159, 16, 241, 0.6),
        0 0 35px rgba(255, 87, 219, 0.5),
        0 0 45px rgba(63, 13, 247, 0.4);
    animation: hologlow 2s infinite alternate ease-in-out;
}

.desktop-workshops-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.desktop-workshop-card {
    background-color: #fff;
    min-width: 0;
    max-width: 360px;
    color: #222;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.desktop-workshop-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.desktop-card-image-container {
    position: relative;
    height: 250px;
}

.desktop-workshop-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.desktop-date-overlay {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.desktop-date-content {
    display: flex;
    align-items: center;
}

.desktop-calendar-icon {
    font-size: 20px;
    margin-right: 6px;
}

.desktop-date-text, .desktop-month-text {
    font-size: 16px;
    margin: 3px 0;
}

.desktop-card-details {
    padding: 20px;
}

.desktop-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.desktop-level-tag {
    border: 1px solid #d9d9d9;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: #f0f0f0;
    font-size: 14px;
}

.desktop-spots-text {
    font-size: 16px;
    margin: 0;
}

.desktop-groups-icon {
    font-size: 20px;
    margin-right: 8px;
}

.desktop-spots-text {
    font-size: 18px;
    margin: 0;
}

.desktop-workshop-title {
    margin: 0 0 12px;
    font-size: 22px;
}

.desktop-workshop-desc {
    margin: 0 0 20px;
    font-size: 16px;
    color: #666;
    line-height: 24px;
}

.desktop-registered-preview {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.desktop-avatar-overlap-small {
    margin-right: -15px;
    border: 2px solid #fff;
}

.desktop-registered-count {
    margin-left: 20px;
    font-size: 16px;
}

.desktop-card-footer {
    text-align: center;
}

.desktop-shimmer-btn {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(45deg, rgb(96, 64, 238), rgb(120, 80, 255));
    border: none;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 12px;
    transition: transform 0.3s ease;
}

.desktop-shimmer-btn:hover {
    transform: scale(1.02);
}

.desktop-shimmer-btn::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.5) 50%,
            hsla(21, 63%, 73%, 0));
    transform: rotate(60deg) translate(-5em, 7.5em);
    animation: moveAfter 2s linear infinite;
}

.desktop-shimmer-btn.registered {
    background: rgba(96, 64, 238, 0.5);
    /* More transparent version */
    cursor: not-allowed;
    opacity: 0.7;
    color: rgba(255, 255, 255, 0.8);
    text-transform: inherit !important;
}

.desktop-shimmer-btn.registered::after {
    animation: moveAfter 4s linear infinite;
    /* Slower shimmer for inactive state */
    background: linear-gradient(180deg,
            hsla(21, 63%, 73%, 0),
            hsla(0, 0%, 100%, 0.2) 50%,
            /* Reduced opacity for shimmer */
            hsla(21, 63%, 73%, 0));
}

.desktop-price-info {
    font-size: 18px;
}

.desktop-original-price {
    text-decoration: line-through;
    color: #666;
}

.desktop-separator {
    margin: 0 8px;
}

.desktop-current-price {
    color: #00cc00;
    font-weight: bold;
}

.mobile-current-price {
    color: #00cc00;
    font-weight: bold;
}

.desktop-colleges-section, .desktop-certificates-section, .desktop-reviews-section {
    margin-top: 80px;
    text-align: center;
    color: #fff;
}

.desktop-section-title {
    font-size: 48px;
    margin: 0 0 20px;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
}

.desktop-section-subtitle {
    color: #bbb6b6;
    font-size: 22px;
    line-height: 34px;
    margin: 0 0 40px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.desktop-certificate-image {
    width: 500px;
    margin: 20px 0;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.desktop-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.mobile-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

/* Animations */
@keyframes hologlow {
    0% {
        text-shadow: 0 0 5px rgba(218, 126, 255, 0.6),
            0 0 15px rgba(159, 16, 241, 0.4),
            0 0 25px rgba(255, 87, 219, 0.3),
            0 0 35px rgba(63, 13, 247, 0.2);
    }

    100% {
        text-shadow: 0 0 15px rgba(218, 126, 255, 0.8),
            0 0 30px rgba(159, 16, 241, 0.6),
            0 0 40px rgba(255, 87, 219, 0.5),
            0 0 50px rgba(63, 13, 247, 0.4);
    }
}

@keyframes moveAfter {
    0% {
        transform: rotate(60deg) translate(-5em, 7.5em);
    }

    100% {
        transform: rotate(60deg) translate(5em, -7.5em);
    }
}

.desktop-certificates-section {
    margin-top: 80px;
    text-align: center;
    color: #fff;
    padding: 40px 0;
}

.desktop-certificates-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 20px auto;
    gap: 40px;
}

.desktop-certificates-text {
    flex: 1;
    text-align: left;
    max-width: 500px;
}

.certificates-highlight {
    font-size: 28px;
    color: #fff;
    margin: 0 0 15px;
    background: linear-gradient(90deg, #da7eff, #9f10f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.certificates-desc {
    color: #bbb6b6;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 20px;
}

.desktop-cta-btn.primary {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(45deg, rgb(96, 64, 238), rgb(120, 80, 255));
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.desktop-cta-btn.primary:hover {
    transform: scale(1.02);
}

.desktop-certificates-image {
    flex: 1;
    text-align: right;
}

.desktop-certificate-image {
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.desktop-certificate-image:hover {
    transform: scale(1.05);
}

.desktop-workshop-youtube-container>div>iframe {
    max-width: 100% !important;
    height: 35vh !important;
}